




























































































import BtnUpload from '@/components/upload/BtnUpload.vue'
import {Component, Vue} from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import {apiAddPlugins, apiEditPlugin, apiGetPluginById, apiGetSoftwareInfoById} from "@/api/setting/plugins";

@Component({
  components: {
    MaterialSelect,
    BtnUpload
  }
})
export default class DevicePluginEdit extends Vue {
  $refs!: { form: any, uploadSoft: any }
  isEdit: boolean = false
  loading = false
  form: any = {
    docs: '', // 操作文档
    video: '', // 操作视频
    soft_url: '', // 插件地址
    plugin_content: '', // 插件描述
    plugin_version: '', // 插件版本
    plugin_name: '', // 插件名称
    soft_id: ''
  }

  rules = {
    plugin_name: [
      {
        required: true,
        message: '请输入插件名称',
        trigger: ['blur', 'change']
      }
    ],
    plugin_version: [
      {
        required: true,
        message: '请输入插件版本号',
        trigger: ['blur', 'change']
      }
    ],
    soft_url: [
      {
        required: true,
        message: '请上传插件',
        trigger: ['blur', 'change']
      }
    ],
    docs: [
      {
        required: true,
        message: '请上传文档',
        trigger: ['blur', 'change']
      }
    ],
    video: [
      {
        required: true,
        message: '请上传操作视频',
        trigger: ['blur', 'change']
      }
    ]
  }

  // // S 上传插件
  //
  // // token
  // token = this.$store.getters.token
  // // 版本号
  // version = config.version
  //
  // // 上传插件接口
  // action = `${config.baseURL}/adminapi/upload/lightFile`
  //
  // // 文件上传成功时的回调
  // handleSuccess(response: any, file: any, fileList: any[]) {
  //   console.log('上传插件', response)
  //   // this.data3DMaterail = response.data
  //   this.form.soft_url = response.data.uri
  // }
  //
  // // 文件上传失败时的回调
  // handleError(file: any) {
  //   this.$message.error(`${file.name}文件上传失败`)
  //   this.$refs.uploadSoft.abort()
  // }
  //
  // // 文件超出个数限制时的回调
  // handleExceed() {
  //   this.$message.warning('当前限制上传 1 个文件，现已超出上传上限，请删除已上传文件后重新上传')
  // }
  //
  // // 删除文件前的回调
  // handleBeforeRemove(file: any) {
  //   return this.$confirm(`确定移除 ${file.name}？`)
  // }

  handleSuccessForVideo(response: any) {
    this.form.video = response.url
  }

  handleSuccessForDocs(response: any) {
    this.form.docs = response.url
  }

  handleSuccessForPlugins(response: any) {
    this.form.soft_url = response.url
  }


  // // 设备插件详情
  // getPluginDetail() {
  //   this.loading = true
  //   apiPluginDetail({id: this.id}).then((res: any) => {
  //     this.form = res
  //     this.loading = false
  //   })
  // }

  // 新增设备类型
  handleSave() {
    this.$refs.form.validate((valid: boolean) => {
      if (valid) {
        // 如果排序没有填写，默认是0
        // 判断是编辑还是新增
        const api = this.isEdit
            ? apiAddPlugins(this.form)
            : apiEditPlugin(this.form)
        // 新增或编辑后，返回上一界面
        api.then(() => {
          this.$router.go(-1)
        })
      } else {
        return false
      }
    })
  }

  created() {
    if (this.$route.query.id) {
      this.form.soft_id = this.$route.query.id
      this.isEdit = true
      apiGetSoftwareInfoById(this.form.soft_id).then(res => {
        this.form.plugin_name = res.soft_name
      })
    } else if (this.$route.query.pluginId) {
      const id: any = this.$route.query.pluginId
      apiGetPluginById(id).then((res) => {
        this.form = res.info
      })
    }
  }
}
